/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import {useEffect, useState} from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

// Icons
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";

import metamask from 'assets/images/metamask.png';

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";

// Vision UI Dashboard components
import GradientBorder from "examples/GradientBorder";

// Authentication layout components
import BasicLayout from "layouts/auth/components/BasicLayout";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import rgba from "assets/theme/functions/rgba";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Images
import bgBasic from "assets/images/background-basic-auth.png";
import {useUser} from "../../context";

import { v4 as uuidv4, v6 as uuidv6 } from 'uuid';
import {ethers} from "ethers";
import {useCookies} from "react-cookie";
import VuiAlert from "../../components/VuiAlert";


function Basic() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const { user, setUser } = useUser();
  const [revealError, setRevealError] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(['ignis-auth']);
  const [errorMessage, setErrorMessage] = useState(null);

  const authenticate = async () => {
    let signedMessage = await signMessage({message: "Login to Ignis AI Labs\n\nNonce: " + uuidv4()});

    if (signedMessage) {
      const tokenResponse = await fetch(process.env.REACT_APP_API_BASE_URL + '/auth/token', {
        method: 'POST',
        mode: "cors",
        body: JSON.stringify({
          sign: signedMessage
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(
          function(response) {
            return response.status === 200 ? response.json() : {};
          }
      );

      if (tokenResponse.error) {
        setErrorMessage(tokenResponse.error);
      }

      if (tokenResponse.token) {
        setCookie("token", tokenResponse.token, {
          SameSite: "Lax",
          path: '/'
        });
        setErrorMessage(null);
        setUser({
          authenticated: true,
          token: tokenResponse.token,
          address: tokenResponse.address,
          license_key_count: tokenResponse.license_key_count,
        });

      }
    }
  }


  const signMessage = async ({ message }) => {
    try {
      await window.ethereum.send("eth_requestAccounts");
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const signature = await signer.signMessage(message);
      const address = await signer.getAddress();

      return {
        message,
        signature,
        address
      };
    } catch (err) {
      setRevealError(err.message);
      return null;
    }
  };


  const validateToken = async (token) => {
    const mfaHash = cookies.mfa ?? null;

    const validToken = await fetch(process.env.REACT_APP_API_BASE_URL + '/token/info', {
      method: 'POST',
      headers: {
        Authentication: "Bearer " + token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({})
    }).then(
        function(response) {
          return response.status === 200 ? response.json() : {};
        }
    );

    if (validToken.error) {
      setErrorMessage(validToken.error);
    }

    if (validToken.address) {
      setErrorMessage(null);
      setUser({
        authenticated: true,
        token: token,
        address: validToken.address,
        license_key_count: validToken.license_key_count,
      });
    } else {
      removeCookie("token");
    }
  }

  useEffect(async () => {
    if (cookies.token && !user.authenticated) {
      await validateToken(cookies.token);
    }
  }, [cookies]);

  return (
    <BasicLayout
      title="Ignis AI Labs"
      description=""

    >
      <GradientBorder borderRadius={borders.borderRadius.form} minWidth="100%" maxWidth="100%">
        <VuiBox
          component="form"
          role="form"
          borderRadius="inherit"
          p="45px"
          sx={({ palette: { secondary } }) => ({
            backgroundColor: secondary.main,
          })}
        >
          <VuiTypography
            color="white"
            fontWeight="bold"
            textAlign="center"
            mb="24px"
            sx={({ typography: { size } }) => ({
              fontSize: size.lg,
            })}
          >
            Log in with
          </VuiTypography>
          <Stack mb="25px" justifyContent="center" alignItems="center" direction="row" spacing={2}>
            <GradientBorder borderRadius="xl">
              <a href="#" onClick={authenticate}>
                <IconButton
                  transition="all .25s ease"
                  justify="center"
                  align="center"
                  bg="rgb(19,21,54)"
                  borderRadius="15px"
                  sx={({ palette: { secondary }, borders: { borderRadius } }) => ({
                    borderRadius: borderRadius.xl,
                    padding: "25px",
                    backgroundColor: secondary.focus,
                    "&:hover": {
                      backgroundColor: rgba(secondary.focus, 0.9),
                    },
                  })}
                >
                  <img src={metamask} width={40} height={40} />
                </IconButton>
              </a>
            </GradientBorder>
          </Stack>


          {errorMessage ?
              <VuiAlert color="error" dismissible>
                {errorMessage}
              </VuiAlert>
              :
              ""
          }

        </VuiBox>
      </GradientBorder>
    </BasicLayout>
  );
}

export default Basic;
