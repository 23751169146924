/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import App from "App";

// Vision UI Context Provider
import { VisionUIControllerProvider } from "context";
import matrixSrc from "assets/matrix.mp4";

/*
          <video autoPlay muted loop id="matrix" style={{
              position: "fixed",
              right: 0,
              bottom: 0,
              minWidth: "100%",
              minHeight: "100%",
              zIndex: -99,
              filter: "brightness(25%)"
          }}>
              <source src={matrixSrc} type="video/mp4"/>
          </video>

 */
ReactDOM.render(
  <HashRouter>
      <VisionUIControllerProvider>
          <video autoPlay muted loop id="matrix" style={{
              position: "fixed",
              right: 0,
              bottom: 0,
              minWidth: "100%",
              minHeight: "100%",
              zIndex: -99,
              filter: "brightness(25%)"
          }}>
              <source src={matrixSrc} type="video/mp4"/>
          </video>
          <App/>
      </VisionUIControllerProvider>
  </HashRouter>,
    document.getElementById("root")
);
