/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

// @mui material components
import Grid from "@mui/material/Grid";
import breakpoints from "assets/theme/base/breakpoints";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Table from "../../../examples/Tables/Table";
import {useEffect, useState} from "react";
import DownloadCard from "../installer/components/DownloadCard";
import {FaCheckCircle, FaCopy, FaRobot} from "react-icons/fa";
import {useUser} from "../../../context";
import {FaDeleteLeft} from "react-icons/fa6";
import VuiButton from "../../../components/VuiButton";
import FormField from "./FormField";
import Card from "@mui/material/Card";
import VuiSelect from "../../../components/VuiSelect";

function Licenses() {
  const { values } = breakpoints;
  const { user, setUser } = useUser();
  const [loadingData, setLoadingData] = useState(false);

  const [rows, setRows] = useState([]);
  const [table, setTable] = useState(null);
  const [licensesLeft, setLicensesLeft] = useState(user.license_key_count);

  const [brokerName, setBrokerName] = useState('');
  const [botName, setBotName] = useState('whalemaker');
  const [accountId, setAccountId] = useState(null);

  const handleBrokerChange = (event) => {
      setBrokerName(event.target.value);
  }

  const handleBotChange = (event) => {
      setBotName(event.value);
  }

  const handleAccountChange = (event) => {
      setAccountId(event.target.value);
  }

  const columns = [
      { name: "Broker", align: "left" },
      { name: "Account ID", align: "left" },
      { name: "Created", align: "left" },
      { name: "License Key", align: "left" },
      /*{ name: "Webhook Url", align: "left" },*/
      { name: "Action", align: "right" },
  ];

  const bots = [
      { value: "whalemaker", label: "WhaleMaker" },
  ];

  const CreateLicense = async () => {


      const response = await fetch(
          process.env.REACT_APP_API_BASE_URL + '/licenses/create',
          {
              method: 'POST',
              body: JSON.stringify({
                 broker: brokerName,
                 botName: botName,
                 accountId: accountId,
              }),
              headers: {
                  Authentication: "Bearer " + user.token,
                  'Content-Type': 'application/json',
              }
          }
      );


      if (response.status !== 200) {
          return false;
      }

      const data = await response.json();

      await LoadLicenses();
  }

  const DeleteLicense = async (licenseId) => {
      if (confirm("Are you sure you want to delete this license?")) {
          const response = await fetch(
              process.env.REACT_APP_API_BASE_URL + '/licenses/remove/' + licenseId,
              {
                  headers: {
                      Authentication: "Bearer " + user.token,
                      'Content-Type': 'application/json',
                  }
              }
          );


          if (response.status !== 200) {
              return false;
          }

          const data = await response.json();

          await LoadLicenses();
      }
  }

  const LoadLicenses = async () => {
      if (loadingData) {
          return;
      }

      setLoadingData(true);

      const licenses = [];

      try {
          const response = await fetch(
              process.env.REACT_APP_API_BASE_URL + '/licenses',
              {
                  headers: {
                      Authentication: "Bearer " + user.token,
                      'Content-Type': 'application/json',
                  }
              }
          );

          if (response.status !== 200) {
              return false;
          }

          const data = await response.json();


          for (let license of data) {

              let licenseKeyCopyState = "hidden";
              var licenseKeyCopyCheck = <FaCheckCircle visibility={licenseKeyCopyState} onClick={(e) => {}} />;

              licenses.push({
                  "Broker": license.brokerName,
                  "Account ID": license.accountId,
                  "Created": new Date(license.createdAt).toLocaleDateString(),
                  "License Key": <div>{license.licenseKey}
                       <FaCopy title={"Copy"} style={{
                           marginLeft: 5,
                           cursor: "pointer",
                       }} onClick={(e) => {
                          navigator.clipboard.writeText(license.licenseKey);
                      }}/>
                  </div>,
                  "Webhook Url": <div>
                      https://api.ignislabs.ai/webhooks/{license.webhookId}/{license.webhookKey}
                      <FaCopy title={"Copy"} style={{
                          marginLeft: 5,
                          cursor: "pointer",
                      }} onClick={(e) => {
                          navigator.clipboard.writeText(`https://api.ignislabs.ai/webhooks/${license.webhookId}/${license.webhookKey}`);
                      }}/>
                  </div>,
                  "Action": <div>
                      <FaDeleteLeft /> <a href={"#/trading-bots/licenses"} onClick={() => {
                          DeleteLicense(license.id);
                          return false;
                  }} style={{color: "#0075ff"}}>Delete</a>
                  </div>
              });
          }

          setRows(licenses);
          setLicensesLeft(user.license_key_count - licenses.length)

      } catch (e) {
        setRows([]);
      }

      setLoadingData(false);
  }

  useEffect(() => {
     LoadLicenses();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
        <VuiBox py={3}>
            <Grid container>
                <Grid item xs={12} lg={7}>
                    <VuiBox mb={3} p={1}>
                        <VuiTypography
                            variant={window.innerWidth < values.sm ? "h3" : "h2"}
                            textTransform="capitalize"
                            fontWeight="bold"
                            color="white"
                        >
                            Licenses
                        </VuiTypography>
                    </VuiBox>
                </Grid>
            </Grid>
        </VuiBox>

        <Table columns={columns} rows={rows} />

        <VuiBox mt={4}>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={9}>
                    <VuiBox mb={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Card id="create-license">
                                    <VuiBox mb="40px">
                                        <VuiTypography variant="lg" color="white" fontWeight="bold">
                                            Create License
                                        </VuiTypography>
                                    </VuiBox>
                                    <VuiBox component="form">
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                    <VuiTypography
                                                        component="label"
                                                        variant="caption"
                                                        color="white"
                                                        fontWeight="bold"
                                                        textTransform="capitalize"
                                                    >
                                                        Bot
                                                    </VuiTypography>
                                                </VuiBox>
                                                <VuiSelect placeholder="WhaleMaker" options={bots} onChange={handleBotChange} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormField
                                                    label="Broker"
                                                    placeholder="Enter name of broker..."
                                                    inputProps={{ type: "text" }}
                                                    value={brokerName}
                                                    onChange={handleBrokerChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormField
                                                    label="Account ID"
                                                    placeholder="Enter MetaTrader Account ID..."
                                                    inputProps={{ type: "text" }}
                                                    value={accountId}
                                                    onChange={handleAccountChange}
                                                />
                                            </Grid>
                                        </Grid>
                                        <VuiBox mb={1} mt={2}>
                                            <VuiTypography variant="body2" color="text">
                                                You can create up to {user.license_key_count} licenses. You have {licensesLeft} licenses left.
                                            </VuiTypography>
                                        </VuiBox>
                                        {licensesLeft > 0 ?
                                            <VuiBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
                                                <VuiBox ml="auto">
                                                    <VuiButton variant="contained" color="info" size="small" onClick={CreateLicense}>
                                                        Create
                                                    </VuiButton>
                                                </VuiBox>
                                            </VuiBox>
                                            :
                                            ""
                                        }
                                    </VuiBox>
                                </Card>
                            </Grid>
                        </Grid>
                    </VuiBox>
                </Grid>
            </Grid>
        </VuiBox>

    </DashboardLayout>
  );
}

export default Licenses;
