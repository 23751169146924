/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

// @mui material components
import Grid from "@mui/material/Grid";
import breakpoints from "assets/theme/base/breakpoints";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Footer from "examples/Footer";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {FaRobot} from "react-icons/fa";
import DownloadCard from "./components/DownloadCard";
import {Suspense, useEffect, useState} from "react";
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {useUser} from "../../../context";

function Installer() {
    const { values } = breakpoints;

    const [tabsOrientation, setTabsOrientation] = useState("horizontal");
    const [tabValue, setTabValue] = useState(0);
    const { user, setUser } = useUser();

    const [loadingData, setLoadingData] = useState(false);

    const tradingBotIdentifiers = [
        'whalemaker'
    ];

    const [tradingBots, setTradingBots] = useState([]);

    useEffect(async () => {
        // A function that sets the orientation state of the tabs.
        function handleTabsOrientation() {
            return window.innerWidth < breakpoints.values.lg
                ? setTabsOrientation("vertical")
                : setTabsOrientation("horizontal");
        }

        /**
         The event listener that's calling the handleTabsOrientation function when resizing the window.
         */
        window.addEventListener("resize", handleTabsOrientation);

        // Call the handleTabsOrientation function to set the state with the initial value.
        handleTabsOrientation();

        await loadTradingBots(0);

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleTabsOrientation);
    }, [tabsOrientation]);

    const handleSetTabValue = async (event, newValue) => {
        setTabValue(newValue);
        setTradingBots([]);

        if (tradingBotIdentifiers[newValue]) {
            await loadTradingBots(newValue);
        } else {
            console.log("bot coming soon");
        }
    }

    const loadTradingBots = async (tabId) => {
        setLoadingData(true);
        setTradingBots([]);

        try {
            const response = await fetch(
                process.env.REACT_APP_API_BASE_URL + '/trading-bots/' + tradingBotIdentifiers[tabId],
                {
                    headers: {
                        Authentication: "Bearer " + user.token,
                        'Content-Type': 'application/json',
                    }
                }
            );

            if (response.status !== 200) {
                return false;
            }

            const data = await response.json();

            const tb = [];
            for (let tradingBot of data) {
                tb.push(<Grid item xs={12} md={6} lg={4}>
                    <DownloadCard key={tradingBot.id}
                        icon={<FaRobot color="white" size="33px" />}
                        title={tradingBot.name}
                        color="info"
                        description={tradingBot.description}
                        dateTime={tradingBot.releaseDate}
                        url={tradingBot.url}
                    />
                </Grid>);
            }

            setTradingBots(tb);

        } catch (e) {

        }

        setLoadingData(false);
    }


  return (
    <DashboardLayout>
      <DashboardNavbar />
        <VuiBox py={3}>
            <Grid container>
                <Grid item xs={12} lg={7}>
                    <VuiBox mb={3} p={1}>
                        <VuiTypography
                            variant={window.innerWidth < values.sm ? "h3" : "h2"}
                            textTransform="capitalize"
                            fontWeight="bold"
                            color="white"
                        >
                            Installer
                        </VuiTypography>
                    </VuiBox>
                </Grid>
            </Grid>
        </VuiBox>

        <VuiBox position="relative">
            <Card
                sx={{
                    px: 3,
                    mt: 2,
                }}
            >
                <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    sx={({ breakpoints }) => ({
                        [breakpoints.up("xs")]: {
                            gap: "16px",
                        },
                        [breakpoints.up("xs")]: {
                            gap: "0px",
                        },
                        [breakpoints.up("xl")]: {
                            gap: "0px",
                        },
                    })}
                >



                    <Grid
                        item
                        xs={12}
                        md={4.3}
                        lg={4}
                        xl={3.8}
                        xxl={7}
                        sx={({ breakpoints }) => ({
                            [breakpoints.only("sm")]: {
                                textAlign: "center",
                            },
                        })}
                    >
                        <VuiBox
                            height="100%"
                            mt={0.5}
                            lineHeight={1}
                            display="flex"
                            flexDirection="column"
                            sx={({ breakpoints }) => ({
                                [breakpoints.only("sm")]: {
                                    justifyContent: "center",
                                    alignItems: "center",
                                    my: "12px"
                                },
                            })}
                        >
                            <VuiTypography variant="lg" color="white" fontWeight="bold">
                                Trading Bots available to you
                            </VuiTypography>
                            <VuiTypography variant="button" color="text" fontWeight="regular">
                                {user.address}
                            </VuiTypography>
                        </VuiBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6.5} xl={6} xxl={4} sx={{ ml: "auto" }}>
                        <AppBar position="static">
                            <Tabs
                                orientation={tabsOrientation}
                                value={tabValue}
                                onChange={handleSetTabValue}
                                sx={{ background: "transparent", display: "flex", justifyContent: "flex-end" }}
                            >
                                <Tab
                                    label="WhaleMaker"
                                    icon={<FaRobot size="16px" color="white" fontWeight="bold" />}
                                />
                                <Tab
                                    label="Bot Coming Soon"
                                    icon={<FaRobot size="16px" color="white" fontWeight="bold" />}
                                />
                            </Tabs>
                        </AppBar>
                    </Grid>
                </Grid>
            </Card>
        </VuiBox>

        {loadingData ?
            <div>Loading data...</div>
            :
            <VuiBox mt={5} mb={1}>
                <Grid container spacing={3}>
                    {tradingBots}
                </Grid>
            </VuiBox>
        }

      <Footer />
    </DashboardLayout>
  );
}

export default Installer;
